@import url("https://use.typekit.net/bpo0rgy.css");

/**
 *  Omitted anti-alising because all of the options look more shit :(
 */

html,
body {
  font-size: 16px;
  line-height: 1.5;

  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;

  margin: 0;
  padding: 0;
  border: none;
}

html {
  background-color: #fff;
  color: #444;

  overflow: auto;
}

body {
  background-color: #eee;
  color: #444;

  overflow: hidden;
}

h1 {
  font-size: 4rem;
  line-height: 1;

  margin: 2rem 0;
  color: #fff;

  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.5;

  margin: 1rem 0;
  color: #000;

  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;
}

p {
  font-size: 1.25rem;
  line-height: 1.5;

  margin: 1rem 0;
  color: #000;

  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;
}

a {
  outline-offset: 0.5em;
}

section {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  min-width: 100vw;

  header {
    flex: 0 1 auto;
    overflow: visible;
    background-color: #581845;
    color: #79466a;

    transition: opacity 1s ease-out;
    opacity: 1;

    &.transparent {
      opacity: 0;
    }

    &.invisible {
      display: none;
    }

    h1,
    h2 {
      max-width: 1024px;
    }

    h1 {
      margin: 2rem auto 0.5rem auto;
      color: #fff;
    }

    h2 {
      display: flex;
      margin: 0.5rem auto 1.5rem auto;
      color: #ccb9c7;

      span {
        flex: 1;
      }

      div {
        display: flex;
        flex-direction: row;

        a {
          display: block;
          margin: 0 .25rem;

          /*
          &:first-child {
            margin-left: 0;
          } */

          &:last-child {
            margin-right: 0;
          }
        }

        svg {
          display: block;

          &.docx {
            width: 60px;
            margin: auto;
          }

          &.pdf {
            width: 44px;
            margin: 2.793px auto;
          }
        }
      }
    }
  }

  main.privacy-policy {
    max-width: 32rem;
    margin: auto;

    h3,
    p {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    h3:has(+ p) {
      margin-bottom: 0;
    }

    h3 + p {
      margin-top: 0;
    }
  }

  main.sunrise-sunset {
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;
    overflow: hidden;

    position: relative;

    min-height: 28rem;
    min-width: 100%;

    button {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0;
      padding: 0;

      background-color: #581845;
      border: 2px solid #581845;

      svg {
        position: absolute;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;

        margin: 0;
        padding: 0;

        path {
          fill: #ccb9c7;
        }
      }

      outline-offset: 2px;

      &:focus {
        outline: 2px solid gold;
      }

      &:focus,
      &:hover {
        background-color: #fff;
        border: 2px solid #444;

        svg path {
          fill: #444;
        }
      }
    }

    dl {
      font-family: monospace;
      text-transform: uppercase;
      user-select: none;
      margin: 1rem 0;
      padding: 0;
    }

    dt,
    dd {
      margin: 0;
      padding: 0;
    }

    dt {
      text-align: right;
      border: none;
      color: #aaa;
    }

    dd {
      text-align: center;
      border: 2px solid #ddd;
      color: #444;

      min-width: calc(24rem + 4px);
      min-height: 8rem;
    }

    dd:has(+ dt) {
      margin-bottom: 2rem;
    }

    dd + dt {
      margin-top: 2rem;
    }
  }

  footer {
    flex: 0 1 auto;
    overflow: hidden;
    background-color: #fff;
    color: #000;

    transition: opacity 1s ease-out;
    opacity: 1;

    &.transparent {
      opacity: 0;
    }

    &.invisible {
      display: none;
    }

    nav {
      display: flex;

      align-items: center;

      &:nth-child(1) {
        justify-content: center;

        /* stylelint-disable-next-line no-descending-specificity */
        a {
          display: block;

          color: #000;

          font-weight: normal;
          font-style: normal;
          text-decoration: none;

          margin: 0.75rem 1rem;

          outline-offset: 2px;

          &:focus {
            outline: 2px solid gold;
          }

          &:focus,
          &:hover {
            text-decoration: none;
          }

          svg.github,
          svg.linkedin {
            display: block;

            height: 2rem;
            width: auto;
          }

          svg.github {
            path {
              fill: #000;
            }
          }

          svg.linkedin {
            g.linked polygon,
            g.linked path {
              fill: #000;
            }

            g.in path.text {
              fill: #fff;
            }

            g.in path.rect {
              fill: #07b;
            }
          }
        }
      }

      &:nth-child(2) {
        justify-content: flex-start;

        /* stylelint-disable-next-line no-descending-specificity */
        a {
          display: block;

          color: rgb(0 119 191);

          font-weight: normal;
          font-style: normal;
          text-decoration: underline;

          margin: 0.75rem 1rem;

          outline-offset: 2px;

          &:visited {
            color: rgb(136 64 145);
          }

          &:focus {
            outline: 2px solid gold;
          }
        }
      }
    }
  }
}

#sunrise,
#sunset {
  font-size: 8rem;
  line-height: 1;
  padding: 0.75rem 1rem;
}

@media screen and (min-width: 395px) {
  section {
    header {
      h2 {
        div {
          margin-top: -46px /* calc(((76px / 2) - 100%)) */;

          svg {
            display: block;

            &.docx {
              width: 75px;
              margin: auto;
            }

            &.pdf {
              width: 55px;
              margin: 3px auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 319px) {
  html,
  body {
    overflow: visible;
  }
}

@media screen and (orientation: landscape) and (max-width: 1023px) {
  section {
    header {
      h1 {
        font-size: 2rem;
        line-height: 1;

        margin: 1.5rem 1rem 0.25rem 1rem;
      }

      h2 {
        font-size: 1rem;
        line-height: 1.25rem;

        margin: 0.25rem 1rem 1rem 1rem;
      }
    }

    main.sunrise-sunset {
      min-height: fit-content;

      dd {
        min-width: calc(12rem + 4px);
        min-height: 4rem;
      }
    }

    footer nav a {
      svg.github,
      svg.linkedin {
        height: 1.5rem;
      }
    }
  }

  #sunrise,
  #sunset {
    font-size: 4rem;
    line-height: 1;

    padding: 0.75rem 1rem;
  }
}

@media screen and (orientation: landscape) and (min-width: 1024px) and (max-width: 1055px) {
  section {
    header {
      h1 {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      h2 {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    main.sunrise-sunset {
      min-height: fit-content;

      dd {
        min-width: calc(12rem + 4px);
        min-height: 4rem;
      }
    }

    footer nav a {
      svg.github,
      svg.linkedin {
        height: 1.5rem;
      }
    }
  }
}

@media screen and (orientation: portrait) and (max-width: 767px) {
  section {
    header {
      h1 {
        font-size: 2rem;
        line-height: 1;

        margin: 1.5rem 1rem 0.25rem 1rem;
      }

      h2 {
        font-size: 1rem;
        line-height: 1.25rem;

        margin: 0.25rem 1rem 1rem 1rem;
      }
    }

    main.sunrise-sunset {
      min-height: fit-content;

      dd {
        min-width: calc(12rem + 4px);
        min-height: 4rem;
      }
    }

    footer nav a {
      svg.github,
      svg.linkedin {
        height: 1.5rem;
      }
    }
  }

  #sunrise,
  #sunset {
    font-size: 4rem;
    line-height: 1;

    padding: 0.75rem 1rem;
  }
}

@media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 799px) {
  section {
    header {
      h1 {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      h2 {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    main.sunrise-sunset {
      min-height: fit-content;

      dd {
        min-width: calc(12rem + 4px);
        min-height: 4rem;
      }
    }

    footer nav a {
      svg.github,
      svg.linkedin {
        height: 1.5rem;
      }
    }
  }
}

@media screen and (orientation: portrait) and (min-width: 800px) and (max-width: 1055px) {
  section {
    header {
      h1 {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      h2 {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    main.sunrise-sunset {
      min-height: fit-content;

      dd {
        min-width: calc(12rem + 4px);
        min-height: 4rem;
      }
    }

    footer nav a {
      svg.github,
      svg.linkedin {
        height: 1.5rem;
      }
    }
  }
}
